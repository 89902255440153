<div class="additional-services-component" fxLayout="row" fxLayoutAlign="center">
        <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
            fxFlex.xl="1140px">
            <h1 class="mat-display-2">Zusätzliche Services</h1>
            <h2 class="mat-display-1">Dienstleistungen</h2>
            <table mat-table [dataSource]="dataSourceServices" class="mat-elevation-z8">
                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef> Dienstleistung </th>
                    <td mat-cell *matCellDef="let element"> {{element.service}} </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> Preis </th>
                    <td mat-cell *matCellDef="let element"> {{element.price}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsServices"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsServices;"></tr>
            </table>
            <h2 class="mat-display-1">Versicherung</h2>
            <table mat-table [dataSource]="dataSourceInsurance" class="mat-elevation-z8">
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Versicherungswert </th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> Prämie pro Jahr </th>
                    <td mat-cell *matCellDef="let element"> {{element.price}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsInsurance"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsInsurance;"></tr>
            </table>
            <div class="documents" fxLayout="row wrap" fxLayoutAlign="center">
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                    fxFlex.md="45" fxFlex.gt-md="30">
                    <fa-icon [icon]="faFilePdf"></fa-icon>
                    <p class="mat-body-1">Onboarding 2/7.</p>
                    <p class="mat-caption">Detaillierte Preise.</p>
                    <a mat-raised-button color="primary" href="../assets/pdf/2_onboarding_preise.pdf"
                        target="_blank">Download</a>
                </div>
                <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Onboarding 3/7.</p>
                        <p class="mat-caption">Versicherung.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/3_onboarding_versicherung.pdf"
                            target="_blank">Download</a>
                    </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>