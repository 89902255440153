import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-request-reservation',
  templateUrl: './dialog-request-reservation.component.html',
  styleUrls: ['./dialog-request-reservation.component.sass']
})
export class DialogRequestReservationComponent implements OnInit {

  form = new FormGroup({
    description: new FormControl('', Validators.required),
    start: new FormControl('', Validators.required),
    end: new FormControl('')
  });

  constructor(public dialogRef: MatDialogRef<DialogRequestReservationComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    const { value, valid } = this.form;
    if (valid) {
      this.dialogRef.close(value);
    }
  }

}
