<div class="event-room-component" fxLayout="row" fxLayoutAlign="center">
    <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
        fxFlex.xl="1140px">
        <h1 class="mat-display-2">Das Carnotzet - unser Veranstaltungsraum</h1>
        <div>
            <img fxFlex="100" src="assets/img/carnotzet-page.jpeg" src.xs="assets/img/carnotzet-page-xs.jpeg"
                src.sm="assets/img/carnotzet-page-sm.jpeg" src.md="assets/img/carnotzet-page-md.jpeg"
                src.lg="assets/img/carnotzet-page-lg.jpeg">
        </div>
        <p class="mat-body-1">Das Carnotzet steht jedem Mieter 1x im Jahr kostenlos zur Verfügung. Am grossen Tisch in
            der Mitte finden bis zu 14 Personen Platz, stehend bietet der Raum Platz für bis zu 25 Personen. Beide
            Räume (Vorraum und Carnotzet) sowie Toiletten
            und Küchenablage mit Gläserwaschmaschine stehen Ihnen zur Nutzung zur Verfügung.</p>
        <p class="mat-body-1">Es stehen Weingläser und Wein-Accessoires sowie Teller und Besteck zur Verfügung,
            restliches benötigtes
            Mobiliar wie Dekoration o.Ä muss vom Mieter mitgebracht werden.</p>
        <p class="mat-body-1"><strong>Benutzung ausserhalb des Vertrages:</strong> CHF 300.00 / Tag (24h)</p>
        <p class="mat-body-1"><strong>Reinigungspauschale:</strong> CHF120.00 (ACHTUNG! diese Pauschale wird immer
            eingefordert)
        </p>
        <div class="documents" fxLayout="row wrap" fxLayoutAlign="center">
            <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                fxFlex.md="45" fxFlex.gt-md="30">
                <fa-icon [icon]="faFilePdf"></fa-icon>
                <p class="mat-body-1">Onboarding 2/7.</p>
                <p class="mat-caption">Detaillierte Preise.</p>
                <a mat-raised-button color="primary" href="../assets/pdf/2_Tarife_Weinlager_Laupen.pdf"
                    target="_blank">Download</a>
            </div>
            <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                fxFlex.md="45" fxFlex.gt-md="30">
                <fa-icon [icon]="faFilePdf"></fa-icon>
                <p class="mat-body-1">Onboarding 6/7.</p>
                <p class="mat-caption">Carnotzet Reglement.</p>
                <a mat-raised-button color="primary" href="../assets/pdf/6_onboarding_carnotzet-reglement.pdf"
                    target="_blank">Download</a>
            </div>
            <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                fxFlex.md="45" fxFlex.gt-md="30">
                <fa-icon [icon]="faFilePdf"></fa-icon>
                <p class="mat-body-1">Onboarding 7/7.</p>
                <p class="mat-caption">Carnotzet Mietvertrag.</p>
                <a mat-raised-button color="primary" href="../assets/pdf/7_onboarding_carnotzet-mietvertrag.pdf"
                    target="_blank">Download</a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>