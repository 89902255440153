import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-event-room',
  templateUrl: './event-room.component.html',
  styleUrls: ['./event-room.component.sass']
})
export class EventRoomComponent implements OnInit {

  faFilePdf = faFilePdf;

  constructor() { }

  ngOnInit() {
  }

}
