import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

export interface PricingElement {
  size: string;
  price: string;
  additionalServices: string;
}

const PRICING_DATA: PricingElement[] = [
  { size: 'Paletten-Parkplatz', price: 'CHF 1.10 / Flasche', additionalServices: 'Keine Zusatzleistung. Geteilter Raum mit anderen Mietern. Weine können nur in Begleitung eines Mitarbeiters des Weinlagers ein- oder ausgelagert werden.' },
  { size: 'Parkplatz', price: 'CHF 2.10 / Flasche', additionalServices: 'Keine Zusatzleistung. Geteilte Box mit anderen Mietern. Weine können nicht selbstständig ein- oder ausgekellert werden.' },
  { size: 'Grösse «XS» (ca. 150 Flaschen)', price: 'CHF 150.00', additionalServices: 'Eigener Schlüssel zum Fach, Next-Day Delivery für Bestellungen bis 12h00, 1x kostenlose Benutzung des Eventraums pro Jahr.' },
  { size: 'Grösse «S» (ca. 200 Flaschen)', price: 'CHF 190.00', additionalServices: 'Eigener Schlüssel zum Fach, Next-Day Delivery für Bestellungen bis 12h00, 1x kostenlose Benutzung des Eventraums pro Jahr.' },
  { size: 'Grösse «M» (ca. 330 Flaschen)', price: 'CHF 270.00', additionalServices: 'Eigener Schlüssel zum Fach, Next-Day Delivery für Bestellungen bis 12h00, 1x kostenlose Benutzung des Eventraums pro Jahr.' },
  { size: 'Grösse «L» (ca. 480 Flaschen)', price: 'CHF 420.00', additionalServices: 'Eigener Schlüssel zum Fach, Next-Day Delivery für Bestellungen bis 12h00, 1x kostenlose Benutzung des Eventraums pro Jahr.' },
  { size: 'Grösse «XL» (ca. 1\'400 Flaschen', price: 'CHF 900.00', additionalServices: 'Eigener Schlüssel zum Fach, Next-Day Delivery für Bestellungen bis 12h00, 1x kostenlose Benutzung des Eventraums pro Jahr.' },
];

@Component({
  selector: 'app-wine-boxes',
  templateUrl: './wine-boxes.component.html',
  styleUrls: ['./wine-boxes.component.sass']
})
export class WineBoxesComponent implements OnInit {

  faFilePdf = faFilePdf;

  constructor() { }

  ngOnInit() {
  }

  displayedColumnsPricing: string[] = ['size', 'price', 'additionalServices'];
  dataSourcePricing = PRICING_DATA;
}
