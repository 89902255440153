<div class="newsletter" fxLayout="row wrap" fxLayoutAlign="center center" fxFlexFill>
    <div class="container-size" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
        fxFlex.xl="1140px">
        <h2 class="mat-display-1">Interesse geweckt?</h2>
        <p class="mat-body-1">Erhalten Sie Neuigkeiten und Informationen über das Projekt aus erster Hand. Jetzt
            registrieren: (wir verwenden www.mailchimp.com)</p>
        <form [formGroup]="mailchimpForm" (ngSubmit)="onSubmit()">
            <mat-form-field fxFill>
                <mat-label>E-Mail</mat-label>
                <input matInput type="email" formControlName="email">
            </mat-form-field>
            <br>
            <button mat-raised-button color="accent" type="submit">Registrieren</button>
        </form>
        <br>
    </div>
</div>