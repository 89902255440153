import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Internationalization
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeFr, 'fr-CH');
registerLocaleData(localeDe, 'de-CH');

// Firebase and AngularFire
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

// Firebase UI
import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';

// Angular Material Animations
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angular Material Components
import { AppMaterialModule } from './app-material/app-material.module';

// Flex Layout
import { FlexLayoutModule } from '@angular/flex-layout';

// Others
import { AuthComponent } from './auth/auth.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

// PWA
import { ServiceWorkerModule } from '@angular/service-worker';
import { InventoryComponent } from './inventory/inventory.component';
import { DialogBodyComponent } from './dialog-body/dialog-body.component';

// Fontawesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Dialogs
import { DialogRequestDeliveryComponent } from './dialog-request-delivery/dialog-request-delivery.component';
import { DialogRequestReservationComponent } from './dialog-request-reservation/dialog-request-reservation.component';
import { DialogInventoryAddComponent } from './dialog-inventory-add/dialog-inventory-add.component';
import { InventoryDetailComponent } from './inventory-detail/inventory-detail.component';
import { MessagesComponent } from './messages/messages.component';
import { DialogInventoryDetailsComponent } from './dialog-inventory-details/dialog-inventory-details.component';

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { WineBoxesComponent } from './wine-boxes/wine-boxes.component';
import { AdditionalServicesComponent } from './additional-services/additional-services.component';
import { EventRoomComponent } from './event-room/event-room.component';
import { PortfolioManagementComponent } from './portfolio-management/portfolio-management.component';
import { PressComponent } from './press/press.component';
import { TechnicalInformationComponent } from './technical-information/technical-information.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { PartnersComponent } from './partners/partners.component';
import { WineBoxComponent } from './wine-box/wine-box.component';
import { WineDetailsComponent } from './wine-details/wine-details.component';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';


// Firebase UI Auth Config
const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
  ],
  tosUrl: '/privacy',
  privacyPolicyUrl: '/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
};

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ProfileComponent,
    HomeComponent,
    LoginComponent,
    InventoryComponent,
    DialogBodyComponent,
    DialogRequestDeliveryComponent,
    DialogRequestReservationComponent,
    DialogInventoryAddComponent,
    InventoryDetailComponent,
    MessagesComponent,
    DialogInventoryDetailsComponent,
    WineBoxesComponent,
    AdditionalServicesComponent,
    EventRoomComponent,
    PortfolioManagementComponent,
    PressComponent,
    TechnicalInformationComponent,
    DownloadsComponent,
    PartnersComponent,
    WineBoxComponent,
    WineDetailsComponent,
    DialogConfirmationComponent,
    EventDetailsComponent,
    DeliveryDetailsComponent,
    ContactComponent,
    FooterComponent,
    FeedbackDetailsComponent,
    FaqComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppMaterialModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [DialogInventoryAddComponent, DialogRequestDeliveryComponent,
    DialogRequestReservationComponent, InventoryDetailComponent, DialogConfirmationComponent]
})
export class AppModule { }
