import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogRequestDeliveryComponent } from '../dialog-request-delivery/dialog-request-delivery.component';
import { DialogRequestReservationComponent } from '../dialog-request-reservation/dialog-request-reservation.component';
import { DialogInventoryAddComponent } from '../dialog-inventory-add/dialog-inventory-add.component';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { faTruck, faWineBottle, faCalendarDay, faBox, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Wine } from '../wine';
import { Event } from '../event';
import { WineService } from '../wine.service';
import { InventoryDetailComponent } from '../inventory-detail/inventory-detail.component';
import { DeliveryService } from '../delivery.service';
import { EventService } from '../event.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.sass']
})
export class InventoryComponent implements OnInit {

  // Icons
  faTruck = faTruck;
  faWineBottle = faWineBottle;
  faCalendarDay = faCalendarDay;
  faBox = faBox;
  faPlus = faPlus;

  // Data
  wines: Observable<Wine[]>;
  selectedWine: Wine;
  events: Observable<Event[]>;

  // Dialogs
  dialogRef: any;

  constructor(
    private matDialog: MatDialog,
    private wineService: WineService,
    private eventService: EventService,
    private deliveryService: DeliveryService,
    private afs: AngularFirestore
  ) { }

  ngOnInit() {
    this.getWines();
    this.getEvents();
  }

  openDialogInventoryAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    const dialogRef = this.matDialog.open(DialogInventoryAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        this.wineService.addWine(result);
      }
    );
  }

  openDialogRequestDelivery() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.width = '80%';
    const dialogRef = this.matDialog.open(DialogRequestDeliveryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        this.deliveryService.addDelivery(result);
      }
    );
  }

  openDialogRequestReservation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.width = '80%';
    const dialogRef = this.matDialog.open(DialogRequestReservationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        this.eventService.addEvent(result);
      }
    );
  }

  getWines(): void {
    this.wines = this.wineService.getWines();
  }

  getEvents(): void {
    this.events = this.eventService.getEvents();
  }

  onSelectWine(wine: Wine) {
    this.selectedWine = wine;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.data = wine;
    const dialogRef = this.matDialog.open(InventoryDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result == "delete") {
          //this.wineService.deleteWine(this.selectedWine);
        } else {
          this.wineService.updateWine(result)
        }
      }
    );
  }
}
