<div class="inventory-component">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label>
        <!--<mat-icon class="example-tab-icon">thumb_up</mat-icon>-->
        My wine
      </ng-template>
      <div class="container-position" fxLayout="row" fxLayoutAlign="center">
        <div class="container-size" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
          fxFlex.xl="1140px" fxLayoutAlign="start center">
          <mat-list role="list">
            <mat-list-item *ngFor="let wine of wines | async" (click)="onSelectWine(wine)">
              <mat-icon mat-list-icon *ngIf="wine.amount >=6"><fa-icon [icon]="faBox"></fa-icon></mat-icon>
              <mat-icon mat-list-icon *ngIf="wine.amount < 6"><fa-icon [icon]="faWineBottle"></fa-icon></mat-icon>
              <h4 mat-line>{{wine.name}} {{wine.year}}</h4>
              <p mat-line>{{wine.amount}}x{{wine.size}}</p>
            </mat-list-item>
            <mat-list-item (click)="openDialogInventoryAdd()"> 
              <mat-icon mat-list-icon><fa-icon [icon]="faPlus"></fa-icon></mat-icon>
              <h4 mat-line>Add wine</h4>
              <p mat-line>Click here to add more wine</p>
            </mat-list-item>
          </mat-list>
          <!--<app-inventory-detail [wine]="selectedWine"></app-inventory-detail>-->
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <!--<mat-icon class="example-tab-icon">thumb_up</mat-icon>-->
        Event room
      </ng-template>
      <div class="container-position" fxLayout="column" fxLayoutAlign="center">
        <div class="container-size" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
          fxFlex.xl="1140px" fxLayoutAlign="center">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let event of events | async">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{event.name}}
                    </mat-panel-title>
                    <mat-panel-description>
                      {{event.start | date:'dd.MM.yyyy'}} - {{event.end | date:'dd.MM.yyyy'}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  {{event.description}}
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <!--<mat-icon class="example-tab-icon">thumb_up</mat-icon>-->
        Options
      </ng-template>
      <div class="container-position" fxLayout="row" fxLayoutAlign="center center">
        <div class="container-size" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
          fxFlex.xl="1140px" fxLayout="row wrap" fxLayoutAlign="center">
          <div class="action" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
            fxFlex.md="45" fxFlex.gt-md="30">
            <fa-icon [icon]="faWineBottle"></fa-icon>
            <p>Manually add wine to your box.</p>
            <button mat-raised-button (click)="openDialogInventoryAdd()">Add wine</button>
          </div>
          <div class="action" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
            fxFlex.md="45" fxFlex.gt-md="30">
            <fa-icon [icon]="faTruck"></fa-icon>
            <p>Send wine from your box.</p>
            <button mat-raised-button (click)="openDialogRequestDelivery()">Order a delivery</button>
          </div>
          <div class="action" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
            fxFlex.md="45" fxFlex.gt-md="30">
            <fa-icon [icon]="faCalendarDay"></fa-icon>
            <p>Make a reservation for the event room.</p>
            <button mat-raised-button (click)="openDialogRequestReservation()">Make a reservation</button>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>