import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.sass']
})
export class DialogConfirmationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogConfirmationComponent>
  ) { }

  ngOnInit() {
  }

  close() {
    console.log('Close.');
    this.dialogRef.close();
  }

  submit() {
    console.log('Confirmation.');
    this.dialogRef.close();
  }
}
