import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../feedback.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.sass']
})
export class FeedbackDetailsComponent implements OnInit {

  title: string;
  id: string;
  feedbackForm;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private feedbackService: FeedbackService,
  ) {
    this.feedbackForm = this.formBuilder.group({
      description: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'new') {
      this.title = 'Neuer Vorschlag';
    } else {
      this.title = '';
    }
  }

  onSubmit() {
    const { value, valid } = this.feedbackForm;
    if (valid) {
      if (this.id === 'new') {
        this.feedbackService.addFeedback(value);
      }
      this.onBack();
    }
  }

  onBack(): void {
    this.location.back();
  }

}
