import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Delivery } from './delivery';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { MessageService } from './message.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  private deliveryCollection: AngularFirestoreCollection<Delivery>;
  deliveries: Observable<Delivery[]>;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.deliveryCollection = afs.collection<Delivery>('deliveries');
    this.deliveries = this.deliveryCollection.valueChanges().pipe(shareReplay(1));
  }

  addDelivery(delivery: Delivery) {
    delivery.userId = this.authService.getUserId();
    this.afs.collection('deliveries').add(delivery);
    this.messageService.openSnackBar('Anfrage gesendet.', '', 3000);
  }
}
