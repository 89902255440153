<div class="contact-component" fxLayout="row" fxLayoutAlign="center">
    <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
        fxFlex.xl="1140px">
        <h1 class="mat-display-2">Kontakt / Impressum</h1>
        <p class="mat-body-1">Weinlager Laupen<br>c/o Weinkellerei Stämpfli AG<br>Bösingenstrasse 28<br>CH – 3177 Laupen
            BE<br>Tel.: 00 41 31 747 94 94<br>Info (at) weinlagerlaupen.ch</p>
        <div>
            <img fxFlex="100" src="assets/img/contact.jpeg" src.xs="assets/img/contact-xs.jpeg" src.sm="assets/img/contact-sm.jpeg" src.md="assets/img/contact-md.jpeg"
                src.lg="assets/img/contact-lg.jpeg">
        </div>
    </div>
</div>
<app-footer></app-footer>