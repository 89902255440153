import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpParams, HttpClient } from '@angular/common/http';
import { MessageService } from '../message.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})

export class HomeComponent implements OnInit {

  

  constructor(
    
  ) {
    
  }

  ngOnInit() {

  }

}