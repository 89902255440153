import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.sass']
})
export class DialogBodyComponent implements OnInit {

  form = new FormGroup({
    wine: new FormControl('', Validators.required),
    amount: new FormControl(''),
    size: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<DialogBodyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
    console.warn(this.form.value);
  }

  saveDialogAddWine() {
    const {value, valid} = this.form;
    if(valid){
        this.dialogRef.close(value);
    } 
  }
}
