import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-request-delivery',
  templateUrl: './dialog-request-delivery.component.html',
  styleUrls: ['./dialog-request-delivery.component.sass']
})
export class DialogRequestDeliveryComponent implements OnInit {

  form = new FormGroup({
    items: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<DialogRequestDeliveryComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    const { value, valid } = this.form;
    if (valid) {
      this.dialogRef.close(value);
    }
  }

}
