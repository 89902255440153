import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-information',
  templateUrl: './technical-information.component.html',
  styleUrls: ['./technical-information.component.sass']
})
export class TechnicalInformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
