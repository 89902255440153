<!-- fxFlexFill uses flex-layout to expand to full screen -->
<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav>
    <div class="sidenav-logo-container" fxLayoutAlign="center center">
      <a routerLink="/" (click)="sidenav.toggle()"><img src="assets/img/logo-320.png"></a>
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <h3 mat-subheader>Angebot</h3>
      <a routerLink="/wine-boxes" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faBorderAll"></fa-icon>
        Weinfächer
      </a>
      <a routerLink="/additional-services" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faConciergeBell"></fa-icon>
        Zusätzliche Services
      </a>
      <a routerLink="/event-room" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faDoorOpen"></fa-icon>
        Das Carnotzet
      </a>
      <a routerLink="/portfolio-management" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faFileInvoice"></fa-icon>
        Portfoliomanagement
      </a>
      <a routerLink="/faq" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faQuestion"></fa-icon>
        FAQ
      </a>
      <h3 mat-subheader>Weitere Informationen</h3>
      <a routerLink="/contact" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        Kontakt
      </a>
      <a routerLink="/press" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faNewspaper"></fa-icon>
        Presse
      </a>
      <a routerLink="/technical-information" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faCogs"></fa-icon>
        Technisches
      </a>
      <a routerLink="/downloads" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faFileDownload"></fa-icon>
        Downloads
      </a>
      <a routerLink="/privacy" (click)="sidenav.toggle()" mat-list-item aria-label="">
        <fa-icon [icon]="faFileDownload"></fa-icon>
        Datenschutz
      </a>
      <h3 mat-subheader>Mein Account</h3>
      <div *ngIf="afAuth.user | async as user; else showLogin">
        <a routerLink="/wine-box" (click)="sidenav.toggle()" mat-list-item aria-label="">
          <fa-icon [icon]="faBoxes"></fa-icon>
          Mein Weinfach
        </a>
        <!--
        <a routerLink="/profile" (click)="sidenav.toggle()" mat-list-item aria-label="">
          <fa-icon [icon]="faUser"></fa-icon>
          Profil
        </a>
        -->
        <mat-action-list>
          <button mat-list-item (click)="authService.logout(); sidenav.toggle()">
            <fa-icon [icon]="faSignOutAlt"></fa-icon>
            Ausloggen
          </button>
        </mat-action-list>
      </div>
      <ng-template #showLogin>
        <a routerLink="/login" (click)="sidenav.toggle()" mat-list-item aria-label="">
          <fa-icon [icon]="faSignInAlt"></fa-icon>
          Einloggen / Registrieren
        </a>
      </ng-template>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-elevation-z24">
      <button mat-icon-button (click)="sidenav.toggle();" aria-label="Toggle Navigation">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 class="">Weinlagerlaupen</h1>
    </mat-toolbar>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>