import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from '../message.service';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})

export class FooterComponent implements OnInit {

  mailchimpForm;
  mailChimpEndpoint = 'https://vinorama.us12.list-manage.com/subscribe/post-json?u=85747ce204bd38a3f4870af28&amp;id=4d5f55a685&';

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
  ) {
    this.mailchimpForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
   }

  ngOnInit() {
  }

  onSubmit() {
    const { value, valid } = this.mailchimpForm;
    if (valid) {
      const params = new HttpParams()
        .set('EMAIL', value.email)
        .set('b_85747ce204bd38a3f4870af28_4d5f55a685', ''); // hidden input name

      const mailChimpUrl = this.mailChimpEndpoint + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.messageService.openSnackBar('Wir haben deine Email erhalten und du wirst bald von uns hören.', 'Schliessen');
        }
        else {
          if(response.msg.startsWith("0 -")){
            this.messageService.openSnackBar('Die Domain von der angegebenen Email ist ungültig (der Teil nach @).', 'Schliessen');
          } else if (response.msg.startsWith(value.email)) {
            this.messageService.openSnackBar("Du hast dich bereits für den Newsletter angemeldet.", 'Schliessen');
          }
        }
      }, error => {
        this.messageService.openSnackBar('Etwas hat nicht geklappt. Komm später wieder.', 'Schliessen');
      });
    }
  }

}
