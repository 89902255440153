import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Event } from './event';
import { formatDate } from '@angular/common';
import { AuthService } from './auth.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private eventCollection: AngularFirestoreCollection<Event>;
  events: Observable<Event[]>;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    const today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.eventCollection = afs.collection<Event>('events', ref => ref.where('start', '>=', today).where('status', '==', 'approved'));
    this.events = this.eventCollection.valueChanges().pipe(shareReplay(1));
  }

  getEvents() {
    return this.events;
  }

  addEvent(event: Event) {
    event.userId = this.authService.getUserId();
    event.status = "pending";
    this.afs.collection('events').add(event);
    this.messageService.openSnackBar('Anfrage gesendet.', '', 3000);
  }
}
