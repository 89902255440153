<form [formGroup]="form" (ngSubmit)="submit()">
    <h2 mat-dialog-title>Order a delivery</h2>
    <mat-dialog-content>
        <div class="example-container" fxLayout="column">
            <mat-form-field>
                <textarea matInput formControlName="items" placeholder="Items and quantity" required></textarea>
            </mat-form-field>
            <mat-form-field>
                <textarea matInput formControlName="address" placeholder="Delivery address" required></textarea>
            </mat-form-field>
            <mat-form-field>
                <input matInput formControlName="date" type="date" placeholder="Date of delivery" required>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="mat-raised-button" mat-dialog-close>Close</button>
        <button mat-raised-button type="submit" color="primary">Submit</button>
    </mat-dialog-actions>
</form>