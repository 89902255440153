<div class="downloads-component" fxLayout="row" fxLayoutAlign="center">
        <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
            fxFlex.xl="1140px">
            <h1 class="mat-display-2">Downloads</h1>
            <div class="documents" fxLayout="row wrap" fxLayoutAlign="center">
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                    fxFlex.md="45" fxFlex.gt-md="30">
                    <fa-icon [icon]="faFilePdf"></fa-icon>
                    <p class="mat-body-1">Onboarding 1/7.</p>
                    <p class="mat-caption">Lageplan der Weinfächer.</p>
                    <a mat-raised-button color="primary" href="../assets/pdf/1_onboarding_weinfächer.pdf"
                        target="_blank">Download</a>
                </div>    
                <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Onboarding 2/7.</p>
                        <p class="mat-caption">Detaillierte Preise.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/2_onboarding_preise.pdf"
                            target="_blank">Download</a>
                    </div>
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Onboarding 3/7.</p>
                        <p class="mat-caption">Versicherung.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/3_onboarding_versicherung.pdf"
                            target="_blank">Download</a>
                    </div>
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Onboarding 4/7.</p>
                        <p class="mat-caption">Allgemeine Geschäftsbedingungen.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/4_onboarding_agb.pdf"
                            target="_blank">Download</a>
                    </div>
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Onboarding 5/7.</p>
                        <p class="mat-caption">Mietvertrag.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/5_onboarding_mietvertrag.pdf"
                            target="_blank">Download</a>
                    </div>
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Onboarding 6/7.</p>
                        <p class="mat-caption">Carnotzet Reglement.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/6_onboarding_carnotzet-reglement.pdf"
                            target="_blank">Download</a>
                    </div>
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Onboarding 7/7.</p>
                        <p class="mat-caption">Carnotzet Mietvertrag.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/7_onboarding_carnotzet-mietvertrag.pdf"
                            target="_blank">Download</a>
                    </div>
                    <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                        fxFlex.md="45" fxFlex.gt-md="30">
                        <fa-icon [icon]="faFilePdf"></fa-icon>
                        <p class="mat-body-1">Appendix 1.</p>
                        <p class="mat-caption">Mietvertrag Parkplatz.</p>
                        <a mat-raised-button color="primary" href="../assets/pdf/8_appendix-1_parkplatz-mietvertrag.pdf"
                            target="_blank">Download</a>
                    </div>
                </div>
        </div>
    </div>
    <app-footer></app-footer>