import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Feedback } from './feedback';
import { MessageService } from './message.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private afs: AngularFirestore,
    private messageService: MessageService,
    private authService: AuthService,
  ) { }

  addFeedback(feedback: Feedback) {
    feedback.userId = this.authService.getUserId();
    this.afs.collection('feedbacks').add(feedback);
    this.messageService.openSnackBar('Vorschlag gesendet.', '', 3000);
  }
}
