import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { InventoryComponent } from './inventory/inventory.component';
import { WineBoxesComponent } from './wine-boxes/wine-boxes.component';
import { AdditionalServicesComponent } from './additional-services/additional-services.component';
import { EventRoomComponent } from './event-room/event-room.component';
import { PortfolioManagementComponent } from './portfolio-management/portfolio-management.component';
import { PressComponent } from './press/press.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { TechnicalInformationComponent } from './technical-information/technical-information.component';
import { PartnersComponent } from './partners/partners.component';
import { WineBoxComponent } from './wine-box/wine-box.component';
import { WineDetailsComponent } from './wine-details/wine-details.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';
import { ContactComponent } from './contact/contact.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  // Offer
  { path: 'wine-boxes', component: WineBoxesComponent },
  { path: 'additional-services', component: AdditionalServicesComponent },
  { path: 'event-room', component: EventRoomComponent },
  { path: 'portfolio-management', component: PortfolioManagementComponent },
  { path: 'faq', component: FaqComponent },
  // Informations
  { path: 'press', component: PressComponent },
  { path: 'technical-information', component: TechnicalInformationComponent },
  { path: 'downloads', component: DownloadsComponent },
  { path: 'partners', component: PartnersComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'privacy', component: PrivacyComponent },
  // Others
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'inventory', component: InventoryComponent },
  { path: 'wine-box', component: WineBoxComponent },
  { path: 'detail/:id', component: WineDetailsComponent },
  { path: 'event/:id', component: EventDetailsComponent },
  { path: 'delivery/:id', component: DeliveryDetailsComponent },
  { path: 'feedback/:id', component: FeedbackDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
