<div class="technical-information-component" fxLayout="row" fxLayoutAlign="center">
        <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
            fxFlex.xl="1140px">
            <h1 class="mat-display-2">Technische Informationen</h1>
            <p class="mat-body-1">Ständige und konstante Temperatur: 14.0-15.50. Grad Celsius</p>
            <p class="mat-body-1">Luftfeuchtigkeit: 70%-80%</p>
            <!--<p class="mat-body-1">Stündliche Signale an Web-App</p>-->
            <p class="mat-body-1">155 Weinfächer, Lagerkapazität: 50’000-80'000 Flaschen</p>
        </div>
    </div>
    <app-footer></app-footer>