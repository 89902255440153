<div class="press-component" fxLayout="row" fxLayoutAlign="center">
  <div
    class="container"
    fxLayout="column"
    fxFlex.xs="100"
    fxFlex.sm="540px"
    fxFlex.md="720px"
    fxFlex.lg="960px"
    fxFlex.xl="1140px"
  >
    <h1 class="mat-display-2">Presse</h1>
    <p class="mat-body-1">
      <a
        href="https://www.telebaern.tv/bern/kanton-bern/bis-80000-flaschen-der-unscheinbare-weinkeller-in-laupen-154407540"
        target="_blank"
        >Bis 80'000 Flaschen - der unscheinbare Weinkeller in Laupen</a
      >
      - TV Bericht auf TeleBärn über das Weinlagerlaupen, CNNMoney Switzerland
      vom 20. November 2019
    </p>
    <p class="mat-body-1">
      <a
        href="https://www.youtube.com/watch?v=GByWs2jt8W0&feature=youtu.be"
        target="_blank"
        >Video</a
      >
      über das Weinlagerlaupen, CNNMoney Switzerland vom 20. November 2019
    </p>
    <p class="mat-body-1">
      <a
        href="https://www.radiofr.ch/freiburg/hoeren/tagesaktuell.html?fbclid=IwAR0i-tLPUw6fHfjr3RgLynPDNZGtimznn-TdlinME05aSgaxP4ZFDSXmHro"
        target="_blank"
        >Interview</a
      >
      mit Nicolas P. Stämpfli, Radio Freiburg vom 5. November 2019
    </p>
    <p class="mat-body-1">
      Edle Tropfe hinter Gittern,
      <a
        href="https://www.bernerzeitung.ch/region/bern/edle-tropfen-hinter-gittern/story/27821969"
        target="_blank"
        >Bericht</a
      >
      auf bernerzeitung.ch vom 7. November 2019
    </p>
    <p class="mat-body-1">
      In Laupen kommt der Wein hinter Gitter,
      <a
        href="https://www.derbund.ch/bern/in-laupen-kommt-der-wein-hinter-gitter/story/25810105"
        target="_blank"
        >Bericht</a
      >
      auf derbund.ch vom 6. November 2019
    </p>
    <p class="mat-body-1">
      Erste "Wein-Bank" in der Region Bern eröffnet,
      <a
        href="https://www.htr.ch/story/erste-wein-bank-in-der-region-bern-eroeffnet-24802.html"
        target="_blank"
        >Bericht</a
      >
      auf htr.ch vom 7. November 2019
    </p>
    <p class="mat-body-1">
      "Alternative Iventstments - In Vino Veritas",
      <a
        href="https://www.cnnmoney.ch/shows/spotlight/videos/alternative-investment-wine-dobiaschofsky-staempfli"
        target="_blank"
        >Reportage</a
      >
      auf cnnmoney.ch vom 15. November 2019
    </p>
    <p class="mat-body-1">
      <a href="../assets/pdf/BärnerBär.pdf" target="_blank">BärnerBär</a>,
      Ausgabe vom 21. Juli 2021
    </p>
    <p class="mat-body-1">
      Weitere Links zu Artikeln, Radiosendungen, Pressecommuniques und weiteres
      folgen...
    </p>
  </div>
</div>
<app-footer></app-footer>
