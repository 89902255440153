<div class="portfolio-management-component" fxLayout="row" fxLayoutAlign="center">
    <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
        fxFlex.xl="1140px">
        <h1 class="mat-display-2">Portfoliomanagement</h1>
        <p class="mat-body-1">Ideal gelagerte Weine erzielen auf Auktionen oder im Sekundärmarkt bessere Preise. Dank
            unserem Anschluss und unseren Verbindungen zu einem weltweiten Handelsnetz können wir Ihre Sammlung auf
            Auftrag verkaufen oder Ihre Sammlung komplettieren. Unsere Website vinorama.ch ist ein Marktplatz
            welcher unserer Mitgliedern zur freien Verfügung steht: erfolgt ein Verkauf, wird eine Kommission zu Handen
            der Weinkellerei Stämpfli AG fällig.</p>
        <div class="documents" fxLayout="row wrap" fxLayoutAlign="center">
            <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                fxFlex.md="45" fxFlex.gt-md="30">
                <fa-icon [icon]="faFilePdf"></fa-icon>
                <p class="mat-body-1">Onboarding 2/7.</p>
                <p class="mat-caption">Detaillierte Preise.</p>
                <a mat-raised-button color="primary" href="../assets/pdf/2_onboarding_preise.pdf"
                    target="_blank">Download</a>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/GByWs2jt8W0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</div>
<app-footer></app-footer>