<div class="wine-details-component" fxLayoutAlign="center">
    <div class="container" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px" fxFlex.xl="1140px">
        <div class="" fxLayout="row">
            <button mat-icon-button (click)="onBack()" aria-label="">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <h1 class="mat-h1">{{title}}</h1>
        </div>
        <form [formGroup]="wineForm" fxLayout="column">
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Jahrgang</mat-label>
                <input matInput type="number" formControlName="year">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Anzahl Flaschen</mat-label>
                <input matInput type="number" formControlName="amount">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Flaschengrösse</mat-label>
                <mat-select formControlName="size">
                    <mat-option value="37.5cl">37.5cl</mat-option>
                    <mat-option value="50cl">50cl</mat-option>
                    <mat-option value="75cl">75cl</mat-option>
                    <mat-option value="150cl">150cl</mat-option>
                    <mat-option value="300cl">300cl</mat-option>
                    <mat-option value="500cl">500cl</mat-option>
                    <mat-option value="600cl">600cl</mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="wine; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <button fxFlex="45" mat-raised-button color="warn" (click)="onDelete()">Löschen</button>
                    <button fxFlex="45" mat-raised-button color="primary" (click)="onSubmit()">Aktualisieren</button>
                </div>
            </ng-template>
            <ng-template #elseBlock>
                <div>
                    <button mat-raised-button type="submit" color="primary" (click)="onSubmit()">Einlagern</button>
                </div>
            </ng-template>
        </form>
    </div>
</div>