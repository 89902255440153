import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DeliveryService } from '../delivery.service';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.sass']
})
export class DeliveryDetailsComponent implements OnInit {

  title: string;
  deliveryForm;
  id: string;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private deliveryService: DeliveryService,
  ) {
    this.deliveryForm = this.formBuilder.group({
      wines: ['', Validators.required],
      address: ['', Validators.required],
      date: ['', Validators.required],
    });
   }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'new') {
      this.title = 'Neuer Lieferungsauftrag';
    } else {
      this.title = '';
    }
  }

  onSubmit() {
    const { value, valid } = this.deliveryForm;
    if (valid) {
      if (this.id === 'new') {
        this.deliveryService.addDelivery(value);
      }
      this.onBack();
    }
  }

  onBack(): void {
    this.location.back();
  }
}
