<div class="home-component" fxLayout="column">
    <div class="header">
        <div class="header-layer" fxLayout="row wrap" fxLayoutAlign="center center">
            <div class="container-size" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
                fxFlex.xl="1140px">
                <div class="page-title" fxLayoutAlign="center center">
                    <h1 class="mat-display-3">Weinlager Laupen</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container-position" fxLayoutAlign="center">
        <div class="container-size" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
            fxFlex.xl="1140px">
            <div class="features-xs" fxLayout="column" fxHide.gt-xs fxShow.xs>
                <mat-grid-list cols="1" rowHeight="320px" gutterSize="5px">
                    <mat-grid-tile>
                        <div class="tile-image">
                            <img src="assets/img/wine-boxes-xs.jpeg">
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Weinfächer</h2>
                            <p class="mat-body-1">Perfekt klimatisierte Weinfächer sorgen für den optimalen Reifeprozess
                                Ihrer Weine. Sie
                                können Ihr 24h zugängliches Weinfach in den Grössen XS-XL auswählen.</p>
                            <a mat-raised-button color="primary" routerLink="/wine-boxes">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <img src="assets/img/services-xs.jpeg">
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Zusätzliche Services</h2>
                            <p class="mat-body-1">Zusätzlich zum Weinfach können Sie Services in Anspruch nehmen. Sei
                                dies die Einfuhr und
                                das einkellern Ihrer Weine oder eine zusätzliche Versicherung von der «Mobiliar».</p>
                            <a mat-raised-button color="primary" routerLink="/additional-services">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <img src="assets/img/carnotzet-xs.jpeg">
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Das Carnotzet – unser Veranstaltungsraum</h2>
                            <p class="mat-body-1">Benutzen Sie unseren Veranstaltungsraum um Ihre Weine mit Freunden zu
                                geniessen. Wir
                                veranstalten auch laufend neue Anlässe rund ums Thema Wein und Genuss. Der
                                Veranstaltungsraum steht unseren Mietern des Weinlagers einmal im Jahr kostenlos zur
                                Verfügung, der Raum kann aber auch von externen Personen gemietet werden.
                            </p>
                            <a mat-raised-button color="primary" routerLink="/event-room">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <img src="assets/img/portfolio-xs.jpeg">
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Portfoliomanagement</h2>
                            <p class="mat-body-1">Ihr Portfolio an feinen und raren Weinen ist bei uns gut aufgehoben.
                                Sie suchen weitere
                                Raritäten oder sie möchten welche verkaufen? Das Weinlager Laupen bietet die
                                Komplettlösung für Anleger.</p>
                            <a mat-raised-button color="primary" routerLink="/portfolio-management">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            <div class="features-gt-xs" fxLayout="column" fxHide.xs fxShow.gt-xs>
                <mat-grid-list cols="2" gutterSize="5px">
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Weinfächer</h2>
                            <p class="mat-body-1">Perfekt klimatisierte Weinfächer sorgen für den optimalen Reifeprozess
                                Ihrer Weine. Sie
                                können Ihr 24h zugängliches Weinfach in den Grössen XS-XL auswählen.</p>
                            <a mat-raised-button color="primary" routerLink="/wine-boxes">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-image">
                            <img src="assets/img/wine-boxes.jpeg" src.sm="assets/img/wine-boxes-sm.jpeg"
                                src.md="assets/img/wine-boxes-md.jpeg" src.lg="assets/img/wine-boxes-lg.jpeg">
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-image">
                            <img src="assets/img/services.jpeg" src.sm="assets/img/services-sm.jpeg"
                                src.md="assets/img/services-md.jpeg" src.lg="assets/img/services-lg.jpeg">
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Zusätzliche Services</h2>
                            <p class="mat-body-1">Zusätzlich zum Weinfach können Sie Services in Anspruch nehmen. Sei
                                dies die Einfuhr und
                                das einkellern Ihrer Weine oder eine zusätzliche Versicherung von der «Mobiliar».</p>
                            <a mat-raised-button color="primary" routerLink="/additional-services">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Das Carnotzet – unser Veranstaltungsraum</h2>
                            <p class="mat-body-1">Benutzen Sie unseren Veranstaltungsraum um Ihre Weine mit Freunden zu
                                geniessen. Wir
                                veranstalten auch laufend neue Anlässe rund ums Thema Wein und Genuss. Der
                                Veranstaltungsraum steht unseren Mietern des Weinlagers einmal im Jahr kostenlos zur
                                Verfügung, der Raum kann aber auch von externen Personen gemietet werden.
                            </p>
                            <a mat-raised-button color="primary" routerLink="/event-room">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-image">
                            <img src="assets/img/carnotzet.jpeg" src.sm="assets/img/carnotzet-sm.jpeg"
                                src.md="assets/img/carnotzet-md.jpeg" src.lg="assets/img/carnotzet-lg.jpeg">
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-image">
                            <img src="assets/img/portfolio.jpeg" src.sm="assets/img/portfolio-sm.jpeg"
                                src.md="assets/img/portfolio-md.jpeg" src.lg="assets/img/portfolio-lg.jpeg">
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <div class="tile-content">
                            <h2 class="mat-title">Portfoliomanagement</h2>
                            <p class="mat-body-1">Ihr Portfolio an feinen und raren Weinen ist bei uns gut aufgehoben.
                                Sie suchen weitere
                                Raritäten oder sie möchten welche verkaufen? Das Weinlager Laupen bietet die
                                Komplettlösung für Anleger.</p>
                            <a mat-raised-button color="primary" routerLink="/portfolio-management">Mehr dazu</a>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>