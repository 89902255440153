import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-inventory-details',
  templateUrl: './dialog-inventory-details.component.html',
  styleUrls: ['./dialog-inventory-details.component.sass']
})
export class DialogInventoryDetailsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogInventoryDetailsComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
      this.dialogRef.close();
  }

}
