import { Component, OnInit } from '@angular/core';
import { faWineBottle, faBox, faPlus, faTruck, faCalendarDay, faComment } from '@fortawesome/free-solid-svg-icons';
import { WineService } from '../wine.service';
import { Wine } from '../wine';
import { Event } from '../event';
import { Observable } from 'rxjs';
import { EventService } from '../event.service';

@Component({
  selector: 'app-wine-box',
  templateUrl: './wine-box.component.html',
  styleUrls: ['./wine-box.component.sass']
})
export class WineBoxComponent implements OnInit {

  faWineBottle = faWineBottle;
  faBox = faBox;
  faPlus = faPlus;
  faTruck = faTruck;
  faCalendarDay = faCalendarDay;
  faComment = faComment;

  wines: Observable<Wine[]>;
  events: Observable<Event[]>;

  constructor(
    private wineService: WineService,
    private eventService: EventService,
  ) { }

  ngOnInit() {
    this.getWines();
    this.getEvents();
  }

  getWines(): void {
    this.wines = this.wineService.getWines();
  }

  getEvents(): void {
    this.events = this.eventService.getEvents();
  }
}
