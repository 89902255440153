import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

export interface ServiceElement {
  service: string;
  price: string;
}

export interface InsuranceElement {
  value: string;
  price: string;
}

const SERVICES_DATA: ServiceElement[] = [
  { service: 'Einkellern auf Auftrag (Warenannahme, Prüfung der Ware, direkt ins Fach legen)', price: 'CHF 15.00 / Bewegung' },
  { service: 'Auskellern (Versand an Wunschadresse, Lieferung mit Lieferservice für Kisten bis 15 kg)', price: 'CHF 15.00 / Bewegung' },
  { service: 'Schätzung', price: 'CHF 75.00 pro Stunde' },
  { service: 'Administration bei Einfuhr- Import und Verzollung', price: 'CHF 75.00 pro Stunde' },
  { service: 'Wein-Investment: Übersicht und Schätzung ende Jahr mit Endjahresbericht', price: 'CHF 75.00 pro Stunde' },
  { service: 'Foto einer spezifischen Flasche oder Kiste', price: 'CHF 10.00 pro Foto' },
];

const INSURANCE_DATA: InsuranceElement[] = [
  { value: "< CHF 10'000.00", price: 'CHF 19.00' },
  { value: "Ab CHF 10'000.00 – CHF 20'000.00", price: 'CHF 38.00' },
  { value: "Ab CHF 20'000.00 – CHF 30’000.00", price: 'CHF 57.00' },
  { value: "Je weitere CHF 10'000.00 Versicherungswert", price: 'CHF 19.00' },
];

@Component({
  selector: 'app-additional-services',
  templateUrl: './additional-services.component.html',
  styleUrls: ['./additional-services.component.sass']
})
export class AdditionalServicesComponent implements OnInit {

  faFilePdf = faFilePdf;

  constructor() { }

  ngOnInit() {
  }

  displayedColumnsServices: string[] = ['service', 'price'];
  dataSourceServices = SERVICES_DATA;

  displayedColumnsInsurance: string[] = ['value', 'price'];
  dataSourceInsurance = INSURANCE_DATA;

}
