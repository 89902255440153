<div class="partners-component" fxLayout="row" fxLayoutAlign="center">
        <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
            fxFlex.xl="1140px">
            <h1 class="mat-display-2">Partner / Realisation</h1>
            <p class="mat-body-1">Wir bedanken uns bei folgenden Partnern für die Umsetzung:</p>
            <!--
            <p class="mat-caption">Kern Studer AG, Samstagern</p>
            <p class="mat-caption">JOGGI AG, Murten</p>
            <p class="mat-caption">Gebr. Chiappetta AG, Bösingen</p>
            <p class="mat-caption">Jeckelmann Treppen AG, Düdingen</p>
            <p class="mat-caption">Manfred Käser, Käser Elektro AG, Bösingen</p>
            <p class="mat-caption">Planwerk4, Bösingen</p>
            <p class="mat-caption">Schönenberger Kühlanlagen AG, Düdingen</p>
            <p class="mat-caption">brewengineering, Düdingen</p>
            <p class="mat-caption">De Ligno, Sugiez</p>
            <p class="mat-caption">Hug & Witschi AG, Bösingen</p>
            <p class="mat-caption">Die Mobiliar, Laupen</p>
            <p class="mat-caption">Nathalie Stöckli, Minestrone-PR, Bern</p>
            <p class="mat-caption">Steve Schneider, Flamatt</p>
            <p class="mat-caption">Laserjet 200 AG, Bösingen</p>
            <p class="mat-caption">Mastra Druck AG, Urtenen-Schönbühl</p>
            -->
        </div>
    </div>
    <app-footer></app-footer>