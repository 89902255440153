import { Injectable } from '@angular/core';
import { Wine } from './wine';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { MessageService } from './message.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class WineService {

  private wineCollection: AngularFirestoreCollection<Wine>;
  wines: Observable<Wine[]>;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.wineCollection = afs.collection<Wine>('wines', ref => ref.where('userId', '==', this.authService.getUserId()));
    this.wines = this.wineCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Wine;
        const id = a.payload.doc.id;
        return { id, ...data };
      })), shareReplay(1)
    );
   }

  getWines() {
    return this.wines;
  }

  getWine(id: string) {
    return this.afs.doc(`wines/${id}`).valueChanges().pipe(shareReplay(1));
  }

  addWine(wine: Wine) {
    wine.userId = this.authService.getUserId();
    this.afs.collection('wines').add(wine);
    this.messageService.openSnackBar('Wein eingelagert.', '', 3000);
  }

  updateWine(wine: Wine) {
    this.wineCollection.doc(wine.id).update(wine);
    this.messageService.openSnackBar('Wein aktualisiert.', '', 3000);
  }

  deleteWine(id: string) {
    this.wineCollection.doc(id).delete();
    this.messageService.openSnackBar('Wein gelöscht.', '', 3000);
  }
}
