<div class="feedback-details-component" fxLayoutAlign="center">
    <div class="container" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px" fxFlex.xl="1140px">
        <div class="" fxLayout="row">
            <button mat-icon-button (click)="onBack()" aria-label="">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <h1 class="mat-h1">{{title}}</h1>
        </div>
        <form [formGroup]="feedbackForm" fxLayout="column">
            <mat-form-field>
                <mat-label>Kommentar</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
            <div>
                <button mat-raised-button type="submit" color="primary" (click)="onSubmit()">Senden</button>
            </div>
        </form>
    </div>
</div>