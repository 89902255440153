<form [formGroup]="form" (ngSubmit)="saveDialogAddWine()">
<h2 mat-dialog-title>This is a Dialog title</h2>
<mat-dialog-content>
    <div class="example-container" fxLayout="column">
        <mat-form-field>
            <input matInput formControlName="wine" placeholder="Wine" required>
        </mat-form-field>
        <mat-form-field>
            <input matInput formControlName="amount" type="number" placeholder="Number">
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="size" placeholder="Size">
                <mat-option value="75cl">75cl</mat-option>
                <mat-option value="50cl">50cl</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="mat-raised-button" mat-dialog-close>Close</button>
    <button mat-raised-button type="submit" color="primary">Save</button>
</mat-dialog-actions>
</form>