import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-inventory-add',
  templateUrl: './dialog-inventory-add.component.html',
  styleUrls: ['./dialog-inventory-add.component.sass']
})
export class DialogInventoryAddComponent implements OnInit {

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    year: new FormControl(''),
    amount: new FormControl(''),
    size: new FormControl('')
  });

  constructor(public dialogRef: MatDialogRef<DialogInventoryAddComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    const { value, valid } = this.form;
    if (valid) {
      this.dialogRef.close(value);
    }
  }

}
