import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.sass']
})
export class DownloadsComponent implements OnInit {

  faFilePdf = faFilePdf;

  constructor() { }

  ngOnInit() {
  }

}
