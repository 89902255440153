import { Component, OnInit } from '@angular/core';
import { User } from '../user';
import { AuthService } from '../auth.service';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {

  profileForm;
  profile: Observable<User>;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.profileForm = this.formBuilder.group({
      firstname: '',
      lastname: '',
      email: { value: '', disabled: true },
      address: '',
    });
  }

  ngOnInit() {
    this.profile = this.authService.getProfile();
    this.updateForm();
  }

  updateForm() {
    this.profile.subscribe(profile => this.profileForm.patchValue(profile));
  }

  onSubmit() {
    const { value, valid } = this.profileForm;
    if (valid) {
      this.authService.updateUser(value);
    }
  }

  onBack() {
    this.router.navigate(['/home']);
  }
}
