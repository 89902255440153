import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { EventService } from '../event.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.sass']
})
export class EventDetailsComponent implements OnInit {

  title: string;
  eventForm;
  id: string;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private eventService: EventService,
  ) {
    this.eventForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      date: ['', Validators.required],
    });
   }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'new') {
      this.title = 'Neue Reservationsanfrage';
    } else {
      this.title = '';
    }
  }

  onSubmit() {
    const { value, valid } = this.eventForm;
    if (valid) {
      if (this.id === 'new') {
        this.eventService.addEvent(value);
      }
      this.onBack();
    }
  }

  onBack(): void {
    this.location.back();
  }
}
