import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { faBorderAll, faConciergeBell, faDoorOpen, faFileInvoice, faNewspaper, faCogs, faBoxes, faUser, faSignInAlt, faSignOutAlt, faFileDownload, faHandsHelping, faEnvelope, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from './message.service';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent {
  title = 'Weinlagerlaupen';
  items: Observable<any[]>;
  faBorderAll = faBorderAll;
  faConciergeBell = faConciergeBell;
  faDoorOpen = faDoorOpen;
  faFileInvoice = faFileInvoice;
  faNewspaper = faNewspaper;
  faCogs = faCogs;
  faBoxes = faBoxes;
  faUser = faUser;
  faSignInAlt = faSignInAlt;
  faSignOutAlt = faSignOutAlt;
  faFileDownload = faFileDownload;
  faHandsHelping = faHandsHelping;
  faEnvelope = faEnvelope;
  faQuestion = faQuestion;

  mailchimpForm;
  mailChimpEndpoint = 'https://vinorama.us12.list-manage.com/subscribe/post-json?u=85747ce204bd38a3f4870af28&amp;id=4d5f55a685&';

  constructor(
    db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    private messageService: MessageService,
    private swUpdate: SwUpdate,
    private _snackBar: MatSnackBar,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    })
    this.mailchimpForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(async () => {
        let snackBarRef = this._snackBar.open('Neue Version verfügbar.', 'Jetzt aktualisieren!');
        snackBarRef.onAction().subscribe(() => {
          window.location.reload();
        });
      });
    }
  }

  logout() {
    this.authService.logout();
  }

  onSubmit() {
    const { value, valid } = this.mailchimpForm;
    if (valid) {
      const params = new HttpParams()
        .set('EMAIL', value.email)
        .set('b_85747ce204bd38a3f4870af28_4d5f55a685', ''); // hidden input name

      const mailChimpUrl = this.mailChimpEndpoint + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.messageService.openSnackBar('Wir haben deine Email erhalten und du wirst bald von uns hören.', 'Schliessen');
        }
        else {
          if(response.msg.startsWith("0 -")){
            this.messageService.openSnackBar('Die Domain von der angegebenen Email ist ungültig (der Teil nach @).', 'Schliessen');
          } else if (response.msg.startsWith(value.email)) {
            this.messageService.openSnackBar("Du hast dich bereits für den Newsletter angemeldet.", 'Schliessen');
          }
        }
      }, error => {
        this.messageService.openSnackBar('Etwas hat nicht geklappt. Komm später wieder.', 'Schliessen');
      });
    }
  }
}
