<div class="wine-box-component">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label>
        Meine Weine
      </ng-template>
      <div class="container-position" fxLayout="row" fxLayoutAlign="center">
        <div class="container-size" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px"
          fxFlex.lg="960px" fxFlex.xl="1140px" fxLayoutAlign="start center">
          <mat-list role="list">
            <mat-list-item *ngFor="let wine of wines | async" routerLink="/detail/{{wine.id}}">
              <mat-icon mat-list-icon *ngIf="wine.amount >=3">
                <fa-icon [icon]="faBox"></fa-icon>
              </mat-icon>
              <mat-icon mat-list-icon *ngIf="wine.amount < 3">
                <fa-icon [icon]="faWineBottle"></fa-icon>
              </mat-icon>
              <h4 mat-line>{{wine.name}} {{wine.year}}</h4>
              <p mat-line>{{wine.amount}}x{{wine.size}}</p>
            </mat-list-item>
            <mat-list-item [routerLink]="['/detail','new']">
              <mat-icon mat-list-icon>
                <fa-icon [icon]="faPlus"></fa-icon>
              </mat-icon>
              <h4 mat-line>Wein einlagern</h4>
              <p mat-line>Hier klicken um Wein einzulagern</p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Carnotzet
      </ng-template>
      <div class="container-position" fxLayout="column" fxLayoutAlign="center">
        <div class="container-size" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
          fxFlex.xl="1140px" fxLayoutAlign="center">
          <mat-accordion *ngIf="(events | async)?.length > 0; else loading">
            <mat-expansion-panel *ngFor="let event of events | async">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{event.name}}
                </mat-panel-title>
                <mat-panel-description>
                  {{event.start | date:'dd.MM.yyyy'}} - {{event.end | date:'dd.MM.yyyy'}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              {{event.description}}
            </mat-expansion-panel>
          </mat-accordion>
          <ng-template #loading>
            <p>Im Moment sind keine Events geplant. Mehr Infos über den Raum
              findest du <a routerLink="/event-room" routerLinkActive="active">hier</a>.
              Buchen kannst du direkt hier unter "Aktionen".
            </p>
          </ng-template>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Aktionen
      </ng-template>
      <div class="container-position" fxLayout="row" fxLayoutAlign="center center">
          <div class="container-size" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
            fxFlex.xl="1140px" fxLayout="row wrap" fxLayoutAlign="center">
            <div class="action" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
              fxFlex.md="45" fxFlex.gt-md="30">
              <fa-icon [icon]="faWineBottle"></fa-icon>
              <p class="mat-body-1">Manuell Wein zum Weinfach hinzufügen.</p>
              <button mat-raised-button routerLink='/detail/new'>Einlagern</button>
            </div>
            <div class="action" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
              fxFlex.md="45" fxFlex.gt-md="30">
              <fa-icon [icon]="faTruck"></fa-icon>
              <p class="mat-body-1">Wein aus dem Weinfach liefern lassen.</p>
              <button mat-raised-button routerLink='/delivery/new'>Liefern</button>
            </div>
            <div class="action" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
              fxFlex.md="45" fxFlex.gt-md="30">
              <fa-icon [icon]="faCalendarDay"></fa-icon>
              <p class="mat-body-1">Den Eventraum "Carnotzet" reservieren.</p>
              <button mat-raised-button routerLink='/event/new'>Reservieren</button>
            </div>
            <div class="action" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
              fxFlex.md="45" fxFlex.gt-md="30">
              <fa-icon [icon]="faComment"></fa-icon>
              <p class="mat-body-1">Du hast eine Idee oder Rückmeldung zum Weinlager oder zur App?</p>
              <button mat-raised-button routerLink='/feedback/new'>Vorschlag machen</button>
            </div>
          </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>