import { Component, OnInit } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-portfolio-management',
  templateUrl: './portfolio-management.component.html',
  styleUrls: ['./portfolio-management.component.sass']
})
export class PortfolioManagementComponent implements OnInit {

  faFilePdf = faFilePdf;

  constructor() { }

  ngOnInit() {
  }

}
