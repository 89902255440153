<div class="privacy-component" fxLayout="row" fxLayoutAlign="center">
  <div
    class="container"
    fxLayout="column"
    fxFlex.xs="100"
    fxFlex.sm="540px"
    fxFlex.md="720px"
    fxFlex.lg="960px"
    fxFlex.xl="1140px"
  >
    <h1 class="mat-display-2">Datenschutz</h1>
    <p class="mat-body-1">
      <strong>Hosting</strong> Diese Website wird auf Google Firebase gehostet
      und verwendet Google Fonts. Somit erfährt Google beim Besuch der Website
      deine IP Adresse.
    </p>
    <p class="mat-body-1">
      <strong>Analytics</strong> Wir verwenden Plausible (www.plausible.io),
      eine datenschutzfreundliche Lösung aus der EU.
    </p>
    <p class="mat-body-1">
      <strong>Newsletter</strong> Für den Newsletter verwenden wir Mailchimp
      (www.mailchimp.com).
    </p>
    <p class="mat-body-1">
      <strong>Benutzung der Website</strong> Alle Informationen welche du
      während der Benutzung der Website angibst werden nicht weitergegeben und
      nur verwendet um die gewünnschten Dienstleistungen abzuwickeln.
    </p>
  </div>
</div>
<app-footer></app-footer>
