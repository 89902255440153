import { Component, OnInit, Input, Inject } from '@angular/core';
import { Wine } from '../wine';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.sass']
})
export class InventoryDetailComponent implements OnInit {

  wineForm;

  @Input() wine: Wine;

  constructor(
    public dialogRef: MatDialogRef<InventoryDetailComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder
  ) {
      this.wine = data;
      this.wineForm = this.formBuilder.group({
        name: this.wine.name,
        year: this.wine.year,
        amount: this.wine.amount,
        size: this.wine.size
      });
   }

  ngOnInit() {
  }

  close() {
    console.log('onClose')
    this.dialogRef.close('close');
  }

  onSubmit(data) {
    console.log('onSubmit')
    this.wine.name = data.name;
    this.wine.year = data.year;
    this.wine.amount = data.amount;
    this.wine.size = data.size;
    this.dialogRef.close(this.wine);
  }

  onDelete() {
    console.log('onDelete')
    this.dialogRef.close('delete');
  }
}
