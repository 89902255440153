<div class="profile-component" fxLayoutAlign="center">
  <div class="container" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px" fxFlex.xl="1140px">
    <button mat-icon-button (click)="onBack()" aria-label="Example icon-button with a heart icon">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h1 class="mat-h1">Dein Profil</h1>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" fxLayout="column">
      <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput type="text" formControlName="firstname">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input matInput type="text" formControlName="lastname">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Adresse (wird auch as Lieferadresse verwendet)</mat-label>
        <textarea matInput formControlName="address" type="text" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <div>
        <!-- Removes automatic fill as child of fxLayout -->
        <button mat-raised-button type="submit" color="primary">Speichern</button>
      </div>
    </form>
  </div>
</div>