import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { WineService } from '../wine.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from '../dialog-confirmation/dialog-confirmation.component';

@Component({
  selector: 'app-wine-details',
  templateUrl: './wine-details.component.html',
  styleUrls: ['./wine-details.component.sass']
})
export class WineDetailsComponent implements OnInit {

  title: string;
  wineForm;
  wine: any;
  id: string;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private wineService: WineService,
    private matDialog: MatDialog,
  ) {
    this.wineForm = this.formBuilder.group({
      name: ['', Validators.required],
      year: [''],
      amount: ['', Validators.required],
      size: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getWine();
  }

  getWine(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'new') {
      this.title = 'Neuen Wein einlagern';
    } else {
      this.title = 'Details';
      this.wineService.getWine(this.id)
        .subscribe(wine => {
          this.wine = wine;
          this.updateForm();
        });
    }
  }

  updateForm(): void {
    this.wineForm.patchValue(this.wine);
  }

  onSubmit() {
    const { value, valid } = this.wineForm;
    if (valid) {
      if (this.id === 'new') {
        this.wineService.addWine(value);
      } else {
        value.id = this.id;
        this.wineService.updateWine(value);
      }
      this.onBack();
    }
  }

  onDelete() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {title: 'Warnung', message: 'Willst du den Wein wirklich löschen?'};
    const dialogRef = this.matDialog.open(DialogConfirmationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        if(result) {
          this.wineService.deleteWine(this.id);
          this.onBack();
        }
      }
    );
  }

  onBack(): void {
    this.location.back();
  }

}
