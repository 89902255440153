import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from 'firebase';
import { FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MessageService } from './message.service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userRef: AngularFirestoreDocument<User>;
  profile: Observable<User>;
  user;

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private messageService: MessageService,
    public router: Router,
  ) {
    this.afAuth.authState.subscribe(this.firebaseAuthChangeListener);
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    })
    /*
    let u = JSON.parse(localStorage.getItem('user'));
    let uid = u.uid;
    console.log('AuthService - ngOnInit: ' + uid);
    this.userRef = this.afs.doc(`users/${uid}`);
    this.profile = this.userRef.valueChanges().pipe(shareReplay(1));
    */
  }

  private firebaseAuthChangeListener(response) {
    // if needed, do a redirect in here
    if (response) {
      //console.log('AuthService Logged in :)');
    } else {
      //console.log('AuthService Logged out :(');
    }
  }

  /**
   * Placeholder method which could be used for a custom login form
   * @param email 
   * @param password 
   */
  async login(email: string, password: string) {
    try {
      await this.afAuth.auth.signInWithEmailAndPassword(email, password)
      this.router.navigate(['wine-box']);
    } catch (e) {
      alert("Error!" + e.message);
    }
  }

  async logout() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['home']);
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  getProfile() {
    return this.profile;
  }

  getUserId(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.uid;
  }

  updateUser(user) {
    this.userRef.update(user);
    this.messageService.openSnackBar('Dein Profil wurde aktualisiert.', '', 3000);
  }

  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    // console.log('Successfull login with firebase ui component.');
    this.router.navigate(['wine-box']);
  }
}
