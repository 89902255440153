<div class="wine-boxes-component" fxLayout="row" fxLayoutAlign="center">
    <div class="container" fxLayout="column" fxFlex.xs="100" fxFlex.sm="540px" fxFlex.md="720px" fxFlex.lg="960px"
        fxFlex.xl="1140px">
        <h1 class="mat-display-2">Weinfächer</h1>
        <div>
            <img fxFlex="100" src="assets/img/wine-boxes-page.jpeg" src.xs="assets/img/wine-boxes-page-xs.jpeg"
                src.sm="assets/img/wine-boxes-page-sm.jpeg" src.md="assets/img/wine-boxes-page-md.jpeg"
                src.lg="assets/img/wine-boxes-page-lg.jpeg">
        </div>
        <h2 class="mat-display-1">Übersicht</h2>
        <table mat-table [dataSource]="dataSourcePricing" class="mat-elevation-z8">
            <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef> Grösse </th>
                <td mat-cell *matCellDef="let element"> {{element.size}} </td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> Preis (pro Jahr) </th>
                <td mat-cell *matCellDef="let element"> {{element.price}} </td>
            </ng-container>
            <ng-container matColumnDef="additionalServices">
                <th mat-header-cell *matHeaderCellDef> Zusatzleistungen </th>
                <td mat-cell *matCellDef="let element"> {{element.additionalServices}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsPricing"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPricing;"></tr>
        </table>
        <div class="documents" fxLayout="row wrap" fxLayoutAlign="center">
            <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                fxFlex.md="45" fxFlex.gt-md="30">
                <fa-icon [icon]="faFilePdf"></fa-icon>
                <p class="mat-body-1">Onboarding 1/7.</p>
                <p class="mat-caption">Lageplan der Weinfächer.</p>
                <a mat-raised-button color="primary" href="../assets/pdf/1_onboarding_weinfächer.pdf"
                    target="_blank">Download</a>
            </div>
            <div class="document" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="50"
                fxFlex.md="45" fxFlex.gt-md="30">
                <fa-icon [icon]="faFilePdf"></fa-icon>
                <p class="mat-body-1">Onboarding 2/7.</p>
                <p class="mat-caption">Detaillierte Preise.</p>
                <a mat-raised-button color="primary" href="../assets/pdf/2_onboarding_preise.pdf"
                    target="_blank">Download</a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>